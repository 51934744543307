import type { UploadUserPhotoResponse } from '~/data/services/types/user.type';
import type { CustomFetch } from '~/shared/types/custom-fetch.type';
import type { AuthLoginPayload } from '~/data/services/types/auth.type';

interface UpdateUserPayload {
  profile: {
    name: string;
    bio: string | null;
    birthday: Date | null;
    website: string | null;
  };
}

export const UserService = (fetch: CustomFetch) => ({
  async uploadUserPhoto(payload: FormData): Promise<UploadUserPhotoResponse> {
    return await fetch('api/v1/user/settings/profile/photo/upload', {
      method: 'POST',
      localError: true,
      body: payload,
    });
  },

  async updateUserName(name: string): Promise<void> {
    return await fetch('api/v1/user/settings/profile/update-username', {
      method: 'PATCH',
      body: { name },
    });
  },

  async updateProfile(payload: UpdateUserPayload): Promise<void> {
    return await fetch('api/v1/user/settings/profile/update', {
      method: 'PATCH',
      body: {
        display_name: payload.profile.name,
        bio: payload.profile.bio,
        birthday: payload.profile.birthday,
        website: payload.profile.website,
      },
    });
  },

  async changeCurrency(currencyId: number): Promise<void> {
    return await fetch('api/v1/user/change-currency', {
      method: 'PATCH',
      body: {
        currency_id: currencyId,
      },
    });
  },

  async addCredentials(payload: AuthLoginPayload): Promise<void> {
    return await fetch('api/auth/add-credentials', {
      method: 'POST',
      body: payload,
    });
  },
});
